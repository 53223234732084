import React, { useState, useEffect } from "react";
import CloudBackground from "./assets/CloudBackground.png";
import AboutImage from './assets/About.png'; 
import TopLeftImage from './assets/plane2.png';
import BottomRightImage from './assets/plane1.png';
import './styles.css'; // Import the CSS file

export default function About() {
  const [scrollY, setScrollY] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 890);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      if (!animationStarted && isScrolledIntoView()) {
        setAnimationStarted(true);
      }
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 890);
    };

    const isScrolledIntoView = () => {
      const el = document.getElementById('about');
      if (!el) return false;
      const rect = el.getBoundingClientRect();
      return (
        rect.top <= window.innerHeight &&
        rect.bottom >= 0
      );
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleResize(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [animationStarted]);

  return (
    <div
      id="about"
      className="relative flex flex-col items-center justify-center bg-blue-400 min-h-screen pt-5"
      style={{
        backgroundImage: `url(${CloudBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        
      }}
    >
      <div className="overflow-hidden px-9 pt-28 pb-1.5 mt-3 w-full max-md:px-5 max-md:max-w-full relative" style={{ borderBottom: 'none', margin: 0, padding: 0 }}>
        <div className="flex gap-5 max-md:flex-col max-md:gap-0" style={{ margin: 0, padding: 0 }}>
          <div className="flex flex-col items-center justify-center w-full max-md:ml-0 max-md:w-full relative" style={{ margin: 0, padding: 0 }}>
            <div id="about-content" className="flex flex-col items-center justify-center h-full w-full px-10 pt-8 pb-20 mt-32 text-4xl text-center text-black bg-white rounded-[30px] max-md:px-5 max-md:mt-10 max-md:text-xl max-md:rounded-[20px] max-w-full relative">
            <img src={AboutImage} alt="About" className="w-24 md:w-32 lg:w-40" />
              <div className="mt-6 max-md:mt-10 max-md:max-w-full" style={{ padding: 0 }}>
              Hello World (HW) is a student-led organization at Purdue that offers freshmen a beginner-friendly space to dive into the world of technology. With a focus on building technical skills, we host a hackathon designed for all skill levels, providing opportunities to develop innovative projects and collaborate with peers. Participants can network with upperclassmen, compete for exciting rewards, and enjoy free food and swag in a fun, inclusive environment. It's a perfect place for freshmen to explore tech, connect with others, and thrive in their learning journey!
              </div>
            </div>
            {!isSmallScreen && animationStarted && (
              <>
                <img 
                  src={TopLeftImage} 
                  className="absolute w-60 top-left-image" 
                  alt="Top Left" 
                  style={{ 
                    top: '0', 
                    left: '0', 
                    transform: `translateY(${scrollY * 0.5}px)`, 
                    margin: 0, 
                    padding: 0 
                  }} 
                />
                <img 
                  src={BottomRightImage} 
                  className="absolute w-60 bottom-right-image" 
                  alt="Bottom Right" 
                  style={{ 
                    bottom: '0', 
                    right: '0', 
                    transform: `translateY(-${scrollY * 0.5}px)`, 
                    margin: 0, 
                    padding: 0 
                  }} 
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}