import React, { useState } from "react";
import Logo from "./assets/MainLogo.png";

export default function Navbar() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="w-screen fixed z-50 !max-h-[76px] overflow-hidden items-center justify-center">
        <nav className="bg-[#BAD5FB] px-4 py-1">
          <div className="flex items-center w-full min-h-8">
            <div className="flex">
              <a href="#home">
                <img
                  className="max-h-[48px] sm:max-h-[74px]"
                  src={Logo}
                  alt="logo"
                />
              </a>
            </div>
            <div className="hidden sm:flex sm:ml-4 md:ml-6 items-center  text-black font-semibold text-sm sm:text-lg md:text-xl w-full">
              <ul className="flex sm:space-x-4 items-center">
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#about">About</a>
                </li>
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#schedule">Schedule</a>
                </li>
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#faq">FAQ</a>
                </li>
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#sponsors">Sponsors</a>
                </li>
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#exec">Our Team</a>
                </li>
                <li className="cursor-pointer hover:bg-[#E3EFF2] rounded-md px-1 md:px-2 transition-all">
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>
            <div
              class="self-center mr-6 ml-auto sm:hidden"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 hover:text-gray-200 hover:cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </div>
          </div>
        </nav>
      </div>
      <div
        className={`max-h-screen fixed items-center justify-center z-50 bg-[#5395F4] hidden top-0 left-0 w-full h-full ${
          showModal && "!flex"
        }`}
      >
        <div
          className="text-2xl absolute self-center top-8 right-8 font-bold text-white text-center cursor-pointer"
          onClick={() => {
            setShowModal(false);
          }}
        >
          X
        </div>

        <ul className="text-white flex flex-col text-center justify-center text-4xl font-bold space-y-4">
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#about"
            >
              About
            </a>
          </li>
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#schedule"
            >
              Schedule
            </a>
          </li>
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#faq"
            >
              FAQ
            </a>
          </li>
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#sponsors"
            >
              Sponsors
            </a>
          </li>
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#exec"
            >
              Our Team
            </a>
          </li>
          <li className="cursor-pointer hover:text-[#E3EFF2] rounded-md px-2 py-2 transition-all">
            <a
              onClick={() => {
                setShowModal(false);
              }}
              href="#contact"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
