import React from "react";
import './Sponsors.css';
import sponsor1 from './assets/cswn-logo-resized.JPEG';
import sponsor2 from './assets/soga-logo-resized.JPEG';
import sponsor3 from './assets/cs-logo-resized.JPEG';


export default function Sponsors() {
  // Function to create a tile element
  const createTile = (imageUrl) => {
    return (
      <div className="bg-white rounded-md m-6 mt-8 flex flex-col items-center justify-center tile">
        <img src={imageUrl} alt="Sponsors Logo" className="w-32 h-32 object-cover" />
      </div>
    );
  };

  const imageUrl = [
    sponsor1,
    sponsor2,
    sponsor3
  ]

  // Function to generate tiles for each row
  const generateTilesForRow = (numTiles, rowIndex) => {
    const tiles = [];
    for (let i = 0; i < numTiles; i++) {
      // Replace imageUrl and text with sponsors logo and name
      tiles.push(createTile(imageUrl[i]));
    }
    return tiles;
  };

  // Rows configuration
  const rows = [0, 3, 0];

  return (
    <div className="sponsors-section" id="sponsors">
      <p className="head">OUR SPONSORS</p>
      {rows.map((numTiles, rowIndex) => (
        <div key={rowIndex} className={`row ${rowIndex % 2 !== 0 ? 'row-reverse' : ''}`}>
          {generateTilesForRow(numTiles, rowIndex)}
        </div>
      ))}
    </div>
  );
  
}