import React, { useState } from "react";
import "./FAQ.css";
import faq from "./assets/FAQs.png";

const FAQSection = () => {
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  //try not to make questions too long, div enclosing the question is of fixed height
  const faqs = [
    {
      question: "What is a hackathon?",
      answer:
        "A hackathon is where ideas turn into projects. Build anything you want in the technology domain and share your creation with others."
    },
    {
      question: "What do I need to bring?",
      answer: "Bring your laptop, charger, and any other tech you need to build your project. Also consider a jacket or blanket to keep you warm. Food and drinks are provided!",
    },
    {
      question: "How many people can be on a team?",
      answer: "You can have 1-4 people in a team",
    },
    {
      question: "Can I participate if I don't know how to code?",
      answer: "Hackathons teach you how to code. You don't need prior experience to participate, but do learn as much as you can!",
    },
    {
      question: "I am on the waitlist, can I still get in?",
      answer: "Yes, if you appear on the opening ceremony and we still have spots, you can participate.",
    },
    {
      question: "Do I need to stay in the location always?",
      answer: "No, not unless you want to miss out on the fun events we have planned. Make sure to attend the opening and closing ceremony.",
    },
    {
      question: "How will projects be judged?",
      answer: "Projects will be judged on creativity, technical difficulty, design, and usefulness. Make sure to have a demo ready!",
    },
    {
      question: "Can I work on projects I already started?",
      answer: "No. However, it is okay if you have thought of an idea and have done some research on it.",
    },
    {
      question: "What if I need help?",
      answer: "We have mentors available to help you with your project. You can also ask for help on our Discord server in our many channels specific for sponsor products.",
    },
  ];

  const handleFAQClick = (index) => {
    setSelectedFAQ(selectedFAQ === index ? null : index);
  };

  return (
    <div className="faq-section w-full" id="faq">
      <div className="w-full text-center mt-5 front">
        <div className="img-container">
          <img src={faq} alt="description" height={120} width={120} />
        </div>
        <p className="text-xl email">
          Email us at helloworldpurdue@gmail.com if we missed anything!
        </p>
      </div>
      <div className="mx-auto py-8 myboxcontainer">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-card p-4 cursor-pointer relative ${
              selectedFAQ === index ? "selectedbox" : "bg-white unselectedbox"
            }`}
            style={{ height: selectedFAQ === index ? "fit-content" : "70px" }}
            onClick={() => handleFAQClick(index)}
          >
            <div className="front">
              <h3 className="text-lg mb-3 font-bold">{faq.question}</h3>
            </div>
            {selectedFAQ === index && (
              <div className="faqa back px-4 pt-4 front">
                <h6 className="text-lg">{faq.answer}</h6>
              </div>
            )}
            {selectedFAQ !== index && (
              <div className="arrow-container absolute top-0 right-0 mr-2 mt-2">
                <svg
                  className={`arrow ${selectedFAQ === index ? "arrow-flipped" : ""}`}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 12.707L2.646 7.353L4.06 5.94L8 9.88L11.94 5.94L13.354 7.354L8 12.707Z"
                    fill="#4A5568"
                  />
                </svg>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
