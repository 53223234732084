import React from "react";
import HelloWorld from "./assets/Title.png";
import Cloud1 from "./assets/Cloud1.png";
import Cloud2 from "./assets/Cloud2.png";
import Cloud3 from "./assets/Cloud3.png";
import Cloud4 from "./assets/Cloud4.png";
import Cloud5 from "./assets/Cloud5.png";
import Cloud6 from "./assets/Cloud6.png";
import Cloud7 from "./assets/Cloud7.png";
import Cloud8 from "./assets/Cloud8.png";
import Cloud9 from "./assets/Cloud9.png";
import Cloud11 from "./assets/Cloud11.png";
import Cloud12 from "./assets/Cloud12.png";
import Cloud13 from "./assets/Cloud13.png";
import { useTimer } from "react-timer-hook";

export default function Hero() {
  const { minutes, hours, days } = useTimer({
    expiryTimestamp: new Date("2024-10-26 09:00:00"),
  });

  return (
    <div
      id="home"
      className="flex w-screen h-screen bg-[#5395F4] items-center justify-center flex-col px-4 relative"
    >
      <img
        src={Cloud1}
        alt="logo"
        className="hidden md:block z-[1] absolute top-60 left-60 h-24 -rotate-[1deg]"
      />
      <img
        src={Cloud2}
        alt="logo"
        className="hidden md:block z-[1] absolute top-32 left-[40%] right-[60%] h-16"
      />
      <img
        src={Cloud3}
        alt="logo"
        className="hidden md:block absolute top-32 left-[50%] right-[50%] h-20 z-[2]"
      />
      <img
        src={Cloud3}
        alt="logo"
        className="hidden md:block absolute top-52 right-64 h-20 z-[2]"
      />
      <img
        src={Cloud4}
        alt="logo"
        className="hidden md:block z-[1] absolute top-68 right-36 h-16"
      />
      <img
        src={Cloud5}
        alt="logo"
        className="hidden md:block z-[1] absolute top-36 right-28 h-16"
      />
      <img
        src={Cloud6}
        alt="logo"
        className="hidden md:block z-[1] absolute top-24 left-0 h-16"
      />

      <img
        src={Cloud7}
        alt="logo"
        className="hidden md:block z-[1] absolute top-[40%] bottom-[60%] left-16 h-16"
      />

      <img
        src={Cloud8}
        alt="logo"
        className="hidden md:block z-[1] absolute bottom-20 left-8 h-20"
      />
      <img
        src={Cloud9}
        alt="logo"
        className="hidden md:block z-[1] absolute bottom-20 left-16 h-24 -rotate-[1.5deg]"
      />
      <img
        src={Cloud11}
        alt="logo"
        className="hidden md:block z-[1] absolute top-[50%] bottom-[50%] right-12 h-28 rotate-[1.25deg]"
      />
      <img
        src={Cloud12}
        alt="logo"
        className="hidden md:block z-[1.5] absolute bottom-20 right-32 h-24 rotate-[1deg]"
      />
      <img
        src={Cloud13}
        alt="logo"
        className="hidden md:block z-[1] absolute bottom-20 right-16 h-20 rotate-[1deg]"
      />
      <img
        src={HelloWorld}
        alt="logo"
        className="z-[2] pt-[66px] max-h-[calc(70vh-66px)]"
      />
      <div className="z-[2] flex flex-col items-center -mt-6 sm:-mt-12 md:-mt-20">
        <h5 className="text-lg lg:text-2xl font-semibold text-black text-center">
          Oct 26 - 27, 2024
        </h5>
        <h5 className="mt-1 text-lg lg:text-2xl font-semibold text-black text-center">
          {days} days {hours} hours and {minutes} minutes until Hello World 2024
        </h5>

        {/* Flex container for buttons, switching to column layout on small screens */}
        <div className="flex flex-col md:flex-row gap-4 mt-6">
          <button
            className="bg-[#E9FBFF] text-black text-lg font-semibold py-2.5 px-12 rounded-lg hover:scale-110 hover:shadow-lg hover:bg-[#E9FBFF] transition-all"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLScdsdgkKXzTzknO32otlgR3pSlhnONn73HX61XXgJdozg_QQw/viewform"
              )
            }
          >
            Sign Up
          </button>
          <button
            className="bg-[#E9FBFF] text-black text-lg font-semibold py-2.5 px-12 rounded-lg hover:scale-110 hover:shadow-lg hover:bg-[#E9FBFF] transition-all"
            onClick={() =>
              window.open(
                "https://hello-world-2024-mentor-matching-system.vercel.app/"
              )
            }
          >
            Mentor Matching System
          </button>
        </div>
      </div>
    </div>
  );
}
