import React from 'react';

import './Footer.css';

import instaLogo from './assets/insta.png';
import linkedinLogo from './assets/linkedin.png';
import emailLogo from './assets/email.png';

export default function Footer() {
  const links = [
    { name: "@Instagram", url: "https://www.instagram.com/helloworldpurdue/", image: instaLogo },
    { name: "@LinkedIn", url: "https://www.linkedin.com/company/hello-world-purduehackers/", image: linkedinLogo },
    { name: "@Email", url: "mailto:helloworldpurdue@gmail.com", image: emailLogo },
  ];

  return (
    <div className="w-full h-64 flex flex-col justify-center items-center bg-white border-t-4 pt-10" id="contact">
      <div className="mb-5">
        <p className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl main font-bold tracking-widest">STAY UP TO DATE WITH</p>
      </div>
      <div className="mb-5">
        <p className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl main font-bold mb-5 tracking-widest">HELLO WORLD</p>
      </div>
      <div className="w-2/3 flex justify-evenly items-center flex-wrap">
        {links.map((link, index) => (
          <div key={index} onClick={() => window.open(link.url, "_blank")} className="flex items-center mb-5 hover:scale-105 duration-100">
            <img alt="" src={link.image} className="w-10 mr-2" />
            <button key = {index} className="text-base md:text-lg lg:text-xl xl:text-xl bg-white">
              {link.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
