import React from "react";
import './Exec.css';
import image1 from './assets/Aarini.png';
import image2 from './assets/Aarohi.png';
import image3 from './assets/Adrienne.png';
import image5 from './assets/Keshav.png';
import image6 from './assets/Nikhil.png';
import image7 from './assets/Shreyas.png';

export default function Exec() {
  const createTile = (name, role, imageUrl) => {
    return (
      <div className="tile-container">
        <div className="rounded-md m-6 mb-4 mt-8 flex flex-col items-center justify-center exec-tile">
          <img src={imageUrl} alt={name} className="member-photo" />
        </div>
        <div className="tile-description">
          <p className="member-name">{name}</p>
          <p className="member-role">{role}</p>
        </div>
      </div>
    );
  };

  const teamMembers = [
    { name: "Aarohi Panzade", role: "Co-Director", imageUrl: image2 },
    { name: "Aarini Panzade", role: "Co-Director", imageUrl: image1 },
    { name: "Shreyas Viswanathan", role: "Dev Lead", imageUrl: image7 },
    { name: "Keshav Vyas", role: "Marketing Lead", imageUrl: image5 },
    { name: "Adrienne Peters", role: "Design Lead", imageUrl: image3 },
    { name: "Nikhil Saxena", role: "UX Lead", imageUrl: image6 },
  ];

  return (
    <div className="exec-section mx-auto flex flex-col items-center" id="exec">
      <p className='text-4xl md:text-4xl lg:text-5xl xl:text-6xl m-5 head font-bold tracking-widest pt-5'>OUR TEAM</p>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className={`tile-wrapper ${index < 2 ? 'first-row' : ''}`}>
            {createTile(member.name, member.role, member.imageUrl)}
          </div>
        ))}
      </div>
    </div>
  );
}
