import React from "react";
import Navbar from "./Navbar/Navbar";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Schedule from "./Schedule/Schedule";
import FAQ from "./FAQs/FAQ.js";
import Sponsors from "./Sponsors/Sponsors";
import Exec from "./Exec/Exec";
import Footer from "./Footer/Footer";

export default function App() {
  return (
    <div className="relative">
      <Navbar />
      <Hero />
      <About />
      <Schedule />
      <FAQ />
      <Sponsors />
      <Exec />
      <Footer />
    </div>
  );
}
